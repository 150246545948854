import React from 'react'
import { graphql, Link } from 'gatsby'

import classnames from 'classnames'
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'

import Layout from '../components/layout'

import Seo from '../components/shared/Seo'
import Icons from '../components/shared/Icons'
import Legal from '../components/shared/Legal'
import slugify from '../components/sluggify'

export default class ProteoLitePage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const {
      image,
      title,
      sidebarTitle,
      boxTitle,
      boxText,
      features,
    } = this.props.data.page
    return (
      <Layout>
        <Seo {...this.props.data.seo} />
        <div className="bg-world">
          <Container className="py-4 py-md-5">
            <Row className="mb-3">
              <Col className="text-center" md="12">
                {' '}
                <h2 className="mb-5 text-gold">{title}</h2>
              </Col>
              <Col md="7" className="d-flex flex-column justify-content-end">
                <Row>
                  <Col md="6">
                    <div>
                      <Button
                        style={{ height: '60px', lineHeight: 1 }}
                        size="lg"
                        tag={Link}
                        to="/contact"
                        state={{
                          selected: ' Try For Free',
                        }}
                        className="w-100 text-white py-3  text-center   primary-btn-gradient"
                      >
                        Try For Free
                      </Button>
                      <Button
                        style={{ height: '60px', lineHeight: 1 }}
                        size="lg"
                        tag={Link}
                        to="/contact"
                        state={{
                          selected: 'Contact Us',
                        }}
                        className="w-100 text-white py-3 mt-4  text-center   primary-btn-gradient"
                      >
                        Request a Demo
                      </Button>
                    </div>
                    <Legal dark className="text-center pb-4 pb-md-0" />
                  </Col>
                </Row>
              </Col>

              <Col md="5">
                <img
                  src={image.file.url}
                  alt={image.description}
                  className="w-100"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="py-5">
          <Container>
            <Row>
              <Col md="4">
                <div
                  id="sidebar"
                  className=" text-gold sticky-top"
                  style={{ top: '1vh', maxHeight: '98vh' }}
                >
                  <div className="bg-dark  py-4 px-4">
                    <h4 className="text-primary">{sidebarTitle}</h4>
                    <div className="p-3 pointer">
                      {features.map((item, i) => {
                        return (
                          <div
                            onClick={() => {
                              let el = document.getElementById(item.id)
                              el.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                                inline: 'center',
                              })
                            }}
                          >
                            {item.title}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={{ size: 7, offset: 1 }}>
                {features.map((item, i) => {
                  return (
                    <div key={i} className="mb-7" id={slugify(item.title)}>
                      <h3 className="text-primary text-center" id={item.id}>
                        {item.title}
                      </h3>
                      <p className="lead text-center">{item.lead}</p>
                      {item.gallery && (
                        <div className="row justify-content-center">
                          {item.gallery.map((galleryImage, i) => {
                            return (
                              <div className="col-md-4 col-sm-6">
                                <img
                                  className="w-100"
                                  src={galleryImage.file.url}
                                  alt=""
                                />
                                <small
                                  style={{ fontSize: '70%' }}
                                  className="d-block my-2"
                                >
                                  {galleryImage.description}
                                </small>
                              </div>
                            )
                          })}
                        </div>
                      )}

                      <img
                        src={item.image.file.url}
                        alt={item.image.description}
                        className="w-100 mb-3"
                      />
                      <div
                        dangerouslySetInnerHTML={{ __html: item.text.g.html }}
                      />
                      <Button
                        tag={Link}
                        to="/contact"
                        color="primary"
                        className="px-5 mt-3"
                      >
                        Try for Free
                      </Button>
                    </div>
                  )
                })}
              </Col>
            </Row>
          </Container>
        </div>

        <Icons />
      </Layout>
    )
  }
}

export const query = graphql`
  {
    seo: contentfulTmsFeaturesPage {
      title: metaTitle
      description
      keywords
    }
    page: contentfulTmsFeaturesPage {
      title
      image {
        title
        description
        file {
          url
        }
      }
      sidebarTitle
      boxTitle
      boxText {
        g: childMarkdownRemark {
          html
        }
      }
      features {
        id
        title
        lead
        gallery {
          title
          description
          file {
            url
          }
        }
        image {
          title
          description
          file {
            url
          }
        }
        text {
          g: childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
